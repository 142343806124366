// AWHC Custom Javascript / jQuery

(function($) {
	
	$(".nav-push-toggle").click(function(e) {
	  e.preventDefault();
	  $(".awhc-wrapper").toggleClass("toggled");
	});
	
	$(".push-menu-controls").click(function(e) {
	  e.preventDefault();
	  $(".awhc-wrapper").removeClass("toggled");
	});
		
})( jQuery );